import { tns } from "./../node_modules/tiny-slider/src/tiny-slider";

let carousel = document.querySelector('.carousel');

if (carousel) {
    const slider = tns({
        container: carousel,
        loop: true,
        swipeAngle: false,
        speed: 400,
        controlsContainer: '.controls',
        controlsPosition: 'bottom',
        nav: false,
        responsive: {
            350: {
                edgePadding: 20,
                gutter: 24,
                items: 1
            },
            700: {
                items: 2
            },
            1024: {
                center: true,
                startIndex: 1,
                center: true,
            }
        }
    })
}

document.addEventListener('DOMContentLoaded', function () {
    let controls = document.querySelector('.controls');
    if (controls) {
        controls.classList.add('container');
    }
})




