import { getCookie } from "./cookies"
import { setCookie } from "./cookies"
import { removeCookie } from "./cookies"

const cookieBanner = {
		init() {
				const buttons = document.querySelectorAll('[data-accept-cookies]')
				const cookieBanner = document.querySelector('.cookiebanner');

				let cookie = getCookie("accept-cookies");

				if (cookie.length < 1) {
						Array.from(buttons).forEach(button => {
								button.addEventListener("click", function (e) {

										// prevent default event
										e.preventDefault();

										// set cookie local
										document.cookie = "accept-cookies=1; " + new Date(new Date().getFullYear() + 100, 1, 1).toUTCString();

										// remove banner
										var banner = button.parentNode;
										button.parentNode.parentNode.removeChild(banner);
								})
						})
				} else {
						cookieBanner.style.display = 'none';
				}
		}
}

export default cookieBanner.init()
