const tabs = {
    init: function () {
        let tablinks = document.querySelectorAll(".tablinks");

        for (let i = 0; i < tablinks.length; i++) {
            tablinks[i].addEventListener('click', function () {
                let tabId = this.dataset.tabContent;

                getTab(tabId);
                this.classList.add('active');
            })
        }

        function getTab(tabId) {
            let tabcontent = document.querySelectorAll(".tabcontent"),
                tablinks = document.querySelectorAll(".tablinks");

            for (let i = 0; i < tabcontent.length; i++) {
                let tabContentID = tabcontent[i].dataset.tabId;

                if (tabContentID === tabId) {
                    tabcontent[i].style.display = "block";
                } else {
                    tabcontent[i].style.display = "none";
                }
            }

            //Remove active class on tab links
            for (let i = 0; i < tablinks.length; i++) {
                tablinks[i].classList.remove("active");
            }
        }
        
    }
}

export default tabs.init();
