let form = document.querySelector('.booking-form');

if (form) {
		let goToConfirm = form.querySelector('.confirm-booking-button');
		let bookingFormInfo = form.querySelector('.booking-form-info');
		let bookingFormInfoItems = bookingFormInfo.querySelectorAll('li');

		let data = [];

		goToConfirm.addEventListener('click', function () {
				data = Object.values(form).reduce((obj, field) => {
						obj[field.name] = field.value;
						return obj;
				});

				const d = Object.entries(data);

				for (const [entry, result] of d) {
						//console.log(`Value er ${entry} - ${result}`)

						for (let i of bookingFormInfoItems) {
								let c = i.classList;

								if (c.contains(entry)) {
										let textbox = i.querySelector('span');
										textbox.innerText = result;
								}
						}
				}
		})
}
