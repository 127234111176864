

var x = document.getElementsByClassName("form-step");

if (x) {

		var currentTab = 0; // Current tab is set to be the first tab (0)
		showTab(currentTab); // Display the current tab

		function showTab(n) {
				// This function will display the specified tab of the form...
				var x = document.getElementsByClassName("form-step");
				if (x.length > 0) {
						x[n].style.display = "block";
						//... and fix the Previous/Next buttons:
						if (n == 0) {
								document.getElementById("prevBtn").style.display = "none";
						} else {
								document.getElementById("prevBtn").style.display = "inline";
						}
						//fixStepIndicator(n)
				}
		}

		window.nextPrev = function (n) {
				event.preventDefault();

				let sessionFrom = sessionStorage.getItem('dateFrom');
				let sessionTo = sessionStorage.getItem('dateTo');
				let getClass = event.srcElement.classList;

				if (getClass.contains('check-if-dates')) {
						if (!sessionFrom && !sessionTo) {
								alert("Vælg venligst en dato");
								return;
						}
				}

				// This function will figure out which tab to display
				var x = document.getElementsByClassName("form-step");
				// Hide the current tab:
				x[currentTab].style.display = "none";
				// Increase or decrease the current tab by 1:
				currentTab = currentTab + n;
				// Otherwise, display the correct tab:
				showTab(currentTab);
		}

		//function fixStepIndicator(n) {
		//		// This function removes the "active" class of all steps...
		//		var i, x = document.getElementsByClassName("step");
		//		for (i = 0; i < x.length; i++) {
		//				x[i].className = x[i].className.replace(" active", "");
		//		}
		//		//... and adds the "active" class to the current step:
		//		x[n].className += " active";
		//}

}
