let menu = document.querySelector('.main-navigation');
let toggleMenu = document.querySelectorAll('.menu-toggle');

for (let i = 0; i < toggleMenu.length; i++) {
    toggleMenu[i].addEventListener('click', function () {
        var isClosed = menu.classList.contains('closed');

        menu.setAttribute('class', isClosed ? 'main-navigation open' : 'main-navigation closed');
    });
}
