// Polyfills
import objectFitImages from "object-fit-images";
import objectFitVideos from "object-fit-videos";

objectFitImages();
objectFitVideos();

import "picturefill";
import lazySizes from "lazysizes";

import "details-polyfill";

import './scrollTo'
import calendar from './calendar'
import slider from './slider'
import formSlide from './form-slide'
import tabs from './tabs'
import cookieBanner from './cookiebanner'
import toggleMenu from './mobile-menu'
import './confirm-booking'
