import { Calendar } from '@fullcalendar/core';
import { formatDate } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import daLocale from '@fullcalendar/core/locales/da';
import interactionPlugin from '@fullcalendar/interaction';
import { toMoment, toDuration } from '@fullcalendar/moment';

let calendarEl = document.getElementById('calendar');

if (calendarEl) {

		document.addEventListener('DOMContentLoaded', function () {
				loadCalendar('0')
				let rooms = document.getElementById('rooms')

				if (rooms) {
						setSelectedRoom()

						rooms.addEventListener('change', function (event) {
								loadCalendar(event.target.value)
								setSelectedRoom()
						});
				}
		});

		function setSelectedRoom() {
				let rooms = document.getElementById('rooms')
				var selectedRoomText = rooms.options[rooms.selectedIndex].text;

				let form = document.querySelector(".booking-form");
				let selectedRoom = form.querySelector('.selectedRoom');
				selectedRoom.value = selectedRoomText;
		}

		function loadCalendar(_roomid) {
				let calendarEl = document.getElementById('calendar');
				let nodeId = document.querySelector("input.page-id").value;

				calendarEl.innerHTML = '';

				//Clear and get sessionStorage items
				sessionStorage.clear();
				let sessionFrom = sessionStorage.getItem('dateFrom');
				let sessionTo = sessionStorage.getItem('dateTo');
				let isBooked = false;

				// Cache form inputs
				let form = document.querySelector(".booking-form");
				if (form) {
						let dateInputFrom = form.querySelector('.dateFrom');
						let dateInputTo = form.querySelector('.dateTo');


						let btnNext = document.querySelector('.check-if-dates')
						btnNext.addEventListener('click', function () {
								if (sessionFrom) {
										dateInputFrom.value = sessionFrom;
								}
								if (sessionTo) {
										dateInputTo.value = sessionTo;
								} else {
										dateInputTo.value = sessionFrom;
								}
								form.classList.remove('hidden');
						});

						var calendar = new Calendar(calendarEl, {
								plugins: [dayGridPlugin, interactionPlugin,],
								nextDayThreshold: '00:00:00',
								locale: 'da',
								eventSources: [{
										url: '/Umbraco/Api/events/GetBookings?nodeID=' + nodeId + '&roomID=' + _roomid,
										method: 'GET',
								}
								],
								header: {
										left: 'prev',
										center: 'title',
										right: 'next'
								},
								eventRender: function (info) {

								},
								validRange: function (nowDate) {
										return {
												start: nowDate
										};
								},
								dayRender: function (info) {
										//let content = info.view;
										//console.log(content)
								},
								eventClick: function (info) {

										isBooked = true;
										//console.log('View: ' + info.view.type);

										// change the border color just for fun
										// info.el.style.borderColor = 'red';
								},
								dateClick: function (info) {

										// Get the clicked date
										let m = toMoment(info.date, calendar); // calendar is required
										let fullDate = m.format("DD-MM-YYYY");
																				

										// Check if there is a previously selected start date
										if (sessionFrom && fullDate < sessionFrom) {
												// Display an alert if the clicked date is before the previously selected start date
												alert('Vælg venligst en dato efter den først valgte dato');
												return;
										}

										// Check if there is a previously selected end date
										//if (sessionTo && fullDate < sessionTo) {
										//		// Display an alert if the clicked date is before the previously selected end date
										//		alert('You cannot select dates before the previously selected end date.');
										//		return;
										//}



										let previousDate = info.dayEl.previousSibling;
										let previousDateDate = "";
										let previousDateClass = "";
										let formatpreviousDate = "";
										let ddate = "";

										if (previousDate) {
												previousDateDate = previousDate.dataset.date;
												previousDateClass = previousDate.classList;
												formatpreviousDate = toMoment(previousDateDate, calendar);
												ddate = formatpreviousDate.format("DD-MM-YYYY");
										}

										if (!info.dayEl.classList.contains('date-selected')) {
												if (isBooked) {
														alert('Denne dag er booket. Vælg venligst en anden');
														isBooked = false;
														return;
												} else {
														info.dayEl.style.backgroundColor = '#9ea59d';
														info.dayEl.classList.add('date-selected')

														if (!sessionFrom) {
																sessionStorage.setItem('dateFrom', fullDate)
																sessionFrom = sessionStorage.getItem('dateFrom');

														} else if (sessionFrom && sessionTo) {
																sessionStorage.setItem('dateTo', fullDate)
																sessionTo = sessionStorage.getItem('dateTo');

														} else if (sessionFrom && !sessionTo) {
																sessionStorage.setItem('dateTo', fullDate)
																sessionTo = sessionStorage.getItem('dateTo');
														}

														isBooked = false;
												}

										} else {
												if (fullDate == sessionFrom) {
														sessionStorage.clear();
												}

												else if (fullDate == sessionTo) {

														if (sessionTo && previousDateClass.contains('date-selected')) {
																sessionStorage.setItem('dateTo', ddate)
														} else {
																sessionStorage.removeItem('dateTo');
														}
												} else if (!sessionFrom && sessionTo) {
														sessionStorage.setItem('dateFrom', sessionTo)
												}

												info.dayEl.classList.remove('date-selected')
												info.dayEl.style.backgroundColor = '#D9E2D8';
										}
								},
								nextDayThreshold: '01:00:00',
								locale: daLocale,
								width: 410,
								height: 600,
								//showNonCurrentDates: false,
						});

						calendar.render();
				}
		}

}
